import React, { useEffect } from 'react';

// CSSでアニメーションを設定
const styles = {
  emoji: {
    fontSize: '10rem', // 絵文字のサイズを大きく設定
    position: 'absolute',
    left: '50%', // 横方向の基準を中央に設定
    top: '50%', // 縦方向の基準を中央に設定
    transform: 'translate(-50%, -50%)', // 絵文字の中央を画面中央に揃える
    animation: 'shake 0.5s infinite alternate ease-in-out', // shakeアニメーションを設定
    transformOrigin: 'center center', // 揺れの基点を中央に設定
  },
};

// アニメーションの定義をCSSに追加
const shakeAnimation = `
@keyframes shake {
  0% { transform: translate(-50%, -50%) translateX(0); }
  50% { transform: translate(-50%, -50%) translateX(-20px); }
  100% { transform: translate(-50%, -50%) translateX(20px); }
}
`;

const AnimatedEmoji = ({ emoji, onAnimationEnd }) => {

  // アニメーションが終了したらコールバックを呼ぶ
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onAnimationEnd) onAnimationEnd();
    }, 3000); // アニメーションが3秒後に終了する

    return () => clearTimeout(timer);
  }, [onAnimationEnd]);

  return (
    <>
      {/* アニメーションのスタイルを適用 */}
      <style>{shakeAnimation}</style>
      <div style={styles.emoji}>
        {emoji}
      </div>
    </>
  );
};

export default AnimatedEmoji;
