// src/components/ShakeDetector.jsx

import { useEffect } from 'react';

function ShakeDetector({ onShake }) {
  useEffect(() => {
    let lastTime = 0;
    let lastZ = null;
    const threshold = 12; // 閾値を調整

    function handleMotion(event) {
      const { accelerationIncludingGravity } = event;
      const currentTime = Date.now();

      if (currentTime - lastTime > 100) {
        lastTime = currentTime;

        const { z } = accelerationIncludingGravity;

        if (lastZ !== null) {
          const deltaZ = Math.abs(z - lastZ);

          if (deltaZ > threshold) {
            // 振動の強度を deltaZ とする
            onShake(deltaZ);
          }
        }

        lastZ = z;
      }
    }

    window.addEventListener('devicemotion', handleMotion);

    return () => {
      window.removeEventListener('devicemotion', handleMotion);
    };
  }, [onShake]);

  return null;
}

export default ShakeDetector;
