// src/pages/EmojiPage.jsx

import React, { useState } from 'react';
import EmojiSelector from '../components/EmojiSelector';
import ShakeDetector from '../components/ShakeDetector';
import AnimatedEmoji from '../components/AnimatedEmoji';
import FloatingEmoji from '../components/FloatingEmoji';

function EmojiPage() {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [emojis, setEmojis] = useState([]);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  const params = {
    1: '1',
    2: '2',
    3: '3', // 例として 'star' を設定
    4: '4' // 例として 'smile' を設定
  };

  // 絵文字を paramKey にマッピングする関数
  const getParamKey = (emoji) => {
    switch (emoji) {
      case '😍': // ハートの絵文字の場合
        return '1';
      case '🔥': // 炎の絵文字の場合
        return '2';
      case '🥹':
        return '3';
      default:
        return 'unknown'; // 不明な絵文字の場合
    }
  };

  // POSTリクエスト用のデータを準備する関数
  const preparePostData = (emoji, intensity = null) => {
    const paramKey = getParamKey(emoji);
    const data = {
      params: params[paramKey] || 'unknown'
    };
    if (intensity !== null) {
      data.intensity = intensity; // 必要に応じて振動の強度を含める
    }
    return data;
  };

  // POST通信を行う関数
  const sendPostRequest = async (data) => {
    try {
      const response = await fetch('https://nagoyahackathonbackend-production.up.railway.app/', { // APIエンドポイントを指定
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // 送信するデータをJSON文字列に変換
      });

      if (!response.ok) {
        throw new Error('POSTリクエストに失敗しました');
      }

      const result = await response.json();
      console.log('POSTリクエスト成功:', result);
    } catch (error) {
      console.error('POSTリクエストエラー:', error);
    }
  };

  const requestPermission = () => {
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
        .then((response) => {
          if (response === 'granted') {
            setPermissionGranted(true);
          } else {
            alert('デバイスモーションの許可が得られませんでした');
          }
        })
        .catch((error) => {
          console.error('許可リクエスト中にエラーが発生しました:', error);
        });
    } else {
      setPermissionGranted(true);
    }
  };

  const handleShake = (intensity) => {
    if (selectedEmoji) {
      const emojiCount = Math.min(Math.floor(intensity / 10), 30); // 最大30個まで
      const newEmojis = [];

      for (let i = 0; i < emojiCount; i++) {
        newEmojis.push({
          id: Date.now() + i + Math.random(),
          emoji: selectedEmoji,
        });
      }

      setEmojis((prevEmojis) => [...prevEmojis, ...newEmojis]); // 現在の絵文字を保持して追加

      // データを準備して POST リクエストを送信
      const data = preparePostData(selectedEmoji, intensity);
      sendPostRequest(data);
    }
  };

  // 絵文字が選択されたときにPOST通信を行う
  const handleEmojiSelect = (emoji) => {
    setSelectedEmoji(emoji);

    // データを準備して POST リクエストを送信
    const data = preparePostData(emoji);
    console.log(data);
    sendPostRequest(data);
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-between overflow-hidden bg-gray-100">
      <div className="text-center mt-10 mb-6">
        {!permissionGranted ? (
          <button
            onClick={requestPermission}
            className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-600 transition duration-300 transform hover:scale-105"
          >
            スタート
          </button>
        ) : (
          <div className="text-xl font-semibold text-gray-800">
            {selectedEmoji ? (
              <>
                現在の絵文字：<span className="text-3xl">{selectedEmoji}</span>
              </>
            ) : (
              "絵文字を選択してください"
            )}
            <div className="mt-2 text-lg text-gray-600">
              {/* 追加のステータスメッセージをここに追加できます */}
            </div>
          </div>
        )}
      </div>

      <div className="flex-grow flex justify-center items-center w-full px-4">
        <EmojiSelector onSelect={handleEmojiSelect} /> {/* onSelectでhandleEmojiSelectを呼ぶ */}
      </div>

      {selectedEmoji && (
        <div className="fixed w-full h-full flex justify-center items-center pointer-events-none">
          <AnimatedEmoji
            emoji={selectedEmoji}
            onAnimationEnd={() => setSelectedEmoji(null)}
          />
        </div>
      )}

      <div className="relative w-full h-full overflow-hidden">
        {emojis.map((item) => (
          <FloatingEmoji
            key={item.id}
            emoji={item.emoji}
            // onAnimationEnd={() => handleAnimationEnd(item.id)}
          />
        ))}
      </div>

      <ShakeDetector onShake={handleShake} />
    </div>
  );
}

export default EmojiPage;
