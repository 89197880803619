import React, { useEffect, useState } from 'react';

// 泡のアニメーションのスタイル設定
const generateRandomPosition = () => ({
  left: `${Math.random() * 100}%`,
  animationDuration: `${Math.random() * 2 + 3}s`, // 3秒から5秒の間でランダムなアニメーション時間
  animationDelay: `${Math.random() * 1.5}s`, // 最大1.5秒のランダムなディレイ
});

const FloatingEmoji = ({ emoji, onAnimationEnd }) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    setStyle(generateRandomPosition());

    const timer = setTimeout(() => {
      if (onAnimationEnd) onAnimationEnd();
    }, 5000); // 5秒後にアニメーションが終了

    return () => clearTimeout(timer);
  }, [onAnimationEnd]);

  return (
    <div
      className="floating-emoji"
      style={{
        ...style,
        position: 'absolute',
        bottom: 0,
        fontSize: '2rem',
        opacity: 0,
        animation: 'floatUp 5s ease-in forwards', // 泡のアニメーションを適用
      }}
    >
      {emoji}
    </div>
  );
};

export default FloatingEmoji;
