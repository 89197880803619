// src/components/EmojiSelector.jsx

import React from 'react';

function EmojiSelector({ onSelect }) {
  const emojis = [
    { id: 1, name: '😍', code: '😍' },
    { id: 2, name: '🔥', code: '🔥' },
    { id: 3, name: '🥹', code: '🥹' },
  ];

  return (
    <div className="flex justify-center space-x-4 mt-4">
      {emojis.map((emoji) => (
        <button
          key={emoji.id}
          onClick={() => onSelect(emoji.code)}
          className="text-4xl"
        >
          {emoji.name}
        </button>
      ))}
    </div>
  );
}

export default EmojiSelector;
